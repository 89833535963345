export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';
export const FORGOTPASSWORD_FAIL = 'FORGOTPASSWORD_FAIL';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_FAIL = 'RESETPASSWORD_FAIL';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USERS_LOADED = 'USERS_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_ARTISTS = 'GET_ARTISTS';
export const GET_ARTIST = 'GET_ARTIST';
export const GET_ARTIST_ME = 'GET_ARTIST_ME';
export const UPDATE_ARTIST = 'UPDATE_ARTIST';
export const UPDATE_ARTISTS = 'UPDATE_ARTISTS';
export const UPDATE_ARTIST_ME = 'UPDATE_ARTIST_ME';
export const CLEAR_ARTIST = 'CLEAR_ARTIST';
export const JUMP_TO = 'JUMP_TO';
export const CHANGE_HATS = 'CHANGE_HATS';
export const ARTIST_ERROR = 'ARTIST_ERROR';
export const ARTIST_ME_ERROR = 'ARTIST_ME_ERROR';
export const UPDATE_ARTIST_ERROR = 'UPDATE_ARTIST_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_CALENDLY_USER = 'GET_CALENDLY_USER';
export const CALENDLY_ERROR = 'CALENDLY_ERROR';
export const GET_CALENDLY_SCHEDULED_EVENTS = 'GET_CALENDLY_SCHEDULED_EVENTS';
export const GET_CALENDLY_EVENT_INVITEE = 'GET_CALENDLY_EVENT_INVITEE';
export const REFRESH_CALENDLY = 'REFRESH_CALENDLY';
export const OPEN_NAV_DRAWER = 'OPEN_NAV_DRAWER';
export const CLOSE_NAV_DRAWER = 'CLOSE_NAV_DRAWER';
export const CLOSE_EVENT_EDIT_DRAWER = 'CLOSE_EVENT_EDIT_DRAWER';
// export const OPEN_USER_DRAWER = 'OPEN_USER_DRAWER';
// export const CLOSE_USER_DRAWER = 'CLOSE_USER_DRAWER';
export const setSelectedDates = 'setSelectedDates';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const USER_ROLE_UPDATED = 'USER_ROLE_UPDATED';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const PAGE_LOAD = 'PAGE_LOAD';
export const FLIP_ARTIST_CARD = 'FLIP_ARTIST_CARD';
export const GET_HOSTS = 'GET_HOSTS';
export const GET_HOST = 'GET_HOST';
export const GET_HOST_ME = 'GET_HOST_ME';
export const UPDATE_HOST = 'UPDATE_HOST';
export const UPDATE_HOST_ME = 'UPDATE_HOST_ME';
export const UPDATE_HOST_TERMS_AGREEMENT = 'UPDATE_HOST_TERMS_AGREEMENT';
export const CLEAR_HOST = 'CLEAR_HOST';
export const HOST_RAISE_HAND = 'HOST_RAISE_HAND';
export const HOST_DECLINES = 'HOST_DECLINES';
export const HOST_PROPOSES = 'HOST_PROPOSES';
export const EDIT_ARTIST_EVENT = 'EDIT_ARTIST_EVENT';
export const EDIT_HOST_EVENT = 'EDIT_HOST_EVENT';
export const DELETE_ARTIST_EVENT = 'DELETE_ARTIST_EVENT';
export const DELETE_HOST_EVENT = 'DELETE_HOST_EVENT';
export const DELETE_ADMIN_EVENT = 'DELETE_ADMIN_EVENT';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';
export const GET_EVENTS_OFFERED_TO_HOST = 'GET_EVENTS_OFFERED_TO_HOST';
export const GET_EVENTS_NEAR_ME_TO_HOST = 'GET_EVENTS_NEAR_ME_TO_HOST';
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const GET_THIS_ARTIST_BOOKING_EVENTS = 'GET_THIS_ARTIST_BOOKING_EVENTS';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_THIS_ARTIST_EVENTS = 'GET_THIS_ARTIST_EVENTS';
export const ARTIST_VIEWED_HOST_OFFER = 'ARTIST_VIEWED_HOST_OFFER';
export const ARTIST_ACCEPTED_HOST_OFFER = 'ARTIST_ACCEPTED_HOST_OFFER';
export const ARTIST_DECLINED_HOST_OFFER = 'ARTIST_DECLINED_HOST_OFFER';
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const HOST_ERROR = 'HOST_ERROR';
export const UPDATE_HOST_ERROR = 'UPDATE_HOST_ERROR';
export const REFERRAL_LINK_GENERATED = 'REFERRAL_LINK_GENERATED';
export const REFERRAL_LINK_FAIL = 'REFERRAL_LINK_FAIL';
export const GET_ALL_HOSTS_EDIT = 'GET_ALL_HOSTS_EDIT';
export const TOGGLE_HOST_ADMIN_ACTIVE_STATUS =
    'TOGGLE_HOST_ADMIN_ACTIVE_STATUS';
export const TOGGLE_HOST_ACTIVE_STATUS = 'TOGGLE_HOST_ACTIVE_STATUS';
export const TOGGLE_MY_HOST_ACTIVE_STATUS = 'TOGGLE_MY_HOST_ACTIVE_STATUS';
export const ARTIST_REVIEWS_HOST = 'ARTIST_REVIEWS_HOST';
export const HOST_REVIEWS_EVENT = 'HOST_REVIEWS_EVENT';
export const HOST_UPLOADED_FILES = 'HOST_UPLOADED_FILES';
export const CANCEL_EVENT = 'CANCEL_EVENT';
